"use client";

import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/navigation";
import { useMutation } from "@apollo/client";
import TextInput from "@/components/shared/Input/TextInput";
import AuthCard from "@/components/shared/auth/AuthCard";
import User from "@/public/images/icons/User.svg";
import password from "@/public/images/icons/password.svg";
import {
  setToken,
  setError,
  setLoading,
  setUserId,
  setUserRoles,
} from "@/redux/slices/userSlice";
import { LOGIN_MUTATION } from "@/graphQL/userOperations";
import { RootState } from "@/redux/store"; // Assuming you have a store.ts file
import Link from "next/link";

type FormValues = {
  email: string;
  password: string;
};

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const dispatch = useDispatch();
  const router = useRouter();
  const [login] = useMutation(LOGIN_MUTATION);

  // Use Redux state
  const { loading, error } = useSelector((state: RootState) => state.user);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    dispatch(setLoading(true));
    try {
      const response = await login({
        variables: {
          email: data.email,
          password: data.password,
        },
      });
      dispatch(setToken(response.data.userAuth.login.token));
      dispatch(setUserRoles(response.data.userAuth.login.user.roles));
      dispatch(setUserId(response.data.userAuth.login.user.id));
      router.push("/dashboard");
    } catch (err: any) {
      dispatch(setError(err.message || "An error occurred during login"));
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <AuthCard
      title="Sign In - Welcome Back"
      description="Welcome to our digital world! To take full advantage of all our services and website features, please log in with your account. "
    >
      <form className="mt-8 w-full space-y-7" onSubmit={handleSubmit(onSubmit)}>
        <div className="rounded-md shadow-sm -space-y-px">
          <div className="flex flex-col items-center justify-center gap-6 w-full">
            <TextInput
              id="email"
              label="Email"
              register={register}
              placeholder="Email"
              icon={User}
              type="email"
            />

            <TextInput
              id="password"
              label="Password"
              register={register}
              placeholder="Password"
              icon={password}
              type="password"
              isPassword={true}
            />
          </div>
        </div>

        {error && <p className="text-red-500 text-sm">{error}</p>}

        <div
          className="flex items-center justify-end"
          style={{ marginTop: "16px" }}
        >
          <Link
            href="/reset-password"
            className="font-medium text-[#938DB5] text-[14px]"
          >
            Forgot password?
          </Link>
        </div>

        <button
          type="submit"
          className="h-[60px] mt-2 w-full bg-brightPurple text-white rounded-[10px] transition-colors disabled:opacity-50"
          disabled={loading}
        >
          {loading ? "Signing in..." : "Sign in"}
        </button>
      </form>
    </AuthCard>
  );
};

export default LoginForm;
