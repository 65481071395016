import { gql, QueryResult, useQuery } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    userAuth {
      login(email: $email, password: $password) {
        token
        user {
          id
          roles {
            name
          }
        }
      }
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query GetUserProfile {
    profile {
      createdAt
      email
      firstName
      id
      image
      isCustomer
      lastName
      phone
      updatedAt
      licenseKey
      licenseStatus
      roles {
        name
      }
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation MyMutation(
    $email: String
    $firstName: String
    $lastName: String
    $licenseKey: String
  ) {
    userAuth {
      updateInfo(
        firstName: $firstName
        lastName: $lastName
        email: $email
        licenseKey: $licenseKey
      ) {
        user {
          id
          createdAt
          email
          firstName
          lastName
          licenseKey
        }
      }
    }
  }
`;

export const Reset_Password_MUTATION = gql`
  mutation resetPasswrd($email: String!) {
    userAuth {
      requestResetPassword(email: $email) {
        message
        success
      }
    }
  }
`;
export const CONFIRM_Reset_Password_MUTATION = gql`
  mutation confirmResetPasswrd(
    $email: String!
    $otp: String!
    $password: String!
  ) {
    userAuth {
      confirmResetPasswrd(email: $email, password: $password, otp: $otp) {
        message
        success
      }
    }
  }
`;
export const UPDATE_Password_MUTATION = gql`
  mutation updatePassword($currentPassword: String!, $newPassword: String!) {
    userAuth {
      updatePassword(
        currentPassword: $currentPassword
        newPassword: $newPassword
      ) {
        message
        success
      }
    }
  }
`;

// is On Prem
const ISONPREM = gql`
  query GetIsPrem {
    globalQueries {
      isOnprem
    }
  }
`;
export const isOnPrem = (): QueryResult<{
  globalQueries: { isOnprem: boolean };
}> => {
  return useQuery(ISONPREM);
};

// set poassword
export const SET_PASSWORD = gql`
  mutation MyMutation($password: String!, $token: String!) {
    userAuth {
      setUserPassword(password: $password, token: $token) {
        message
        success
      }
    }
  }
`;
